.block-testimonials{
    background: $primary;
    padding: 75px 0 50px 0;

    .container{
        max-width: 940px;
    }

    .boxes{
        @include layout-flex;
    }

    .box{
        margin: 0 0 25px 0;
        width: 48%;

        @include breakpoint(to tablet-medium){
            width: 100%;
        }


    }

}


.block-testimonials__person{
    color: $white;
    font-size: 18px;
}

.block-testimonials__person__name{
    letter-spacing: .05em;
    margin: 0 0 8px 0;
    text-transform: uppercase;
}

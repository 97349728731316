// colors
$white: #fff;
$black: #505259;
$purple: #4c6bb3;
$green: #6abf6d;

$primary: $purple;
$secondary: $green;

// fonts
$sans: "proxima-nova", sans-serif;
$serif: 'Georgia', serif;

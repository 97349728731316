.component-button{
    border: 1px solid $secondary;
    color: $secondary;
    display: inline-block;
    padding: 15px 20px;
    text-align: center;
    transition: all .4s;

    &:hover{
        border-color: $white;
        color: $white;
        cursor: pointer;
    }

}

.component-button-menu{
    @include levels(1);
    display: block;
    width: 35px;

    @include breakpoint(from tablet-medium){
        display: none;
    }

    .line{
        @include levels(0);
        background: $white;
        height: 3px;
    }

    .line-2{
        margin: 5px 0;
    }

}

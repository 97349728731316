.block-team{
    background: $primary;
    padding: 85px 0 75px 0;

    .container{
        max-width: 940px;
    }

    .boxes{
        @include layout-flex($justify: center);
    }

    .box{
        margin: 0 1% 25px 1%;
        width: 30%;

        @include breakpoint(to tablet-medium){
            width: 100%;
        }


    }
}

.block-team__title{
    color: $white;
    font-size: 24px;
    letter-spacing: .2em;
    margin: 0 0 40px 0;
    text-align: center;
    text-transform: uppercase;
}

.block-team__member__name{
    font-size: 18px;
    letter-spacing: .05em;
    text-transform: uppercase;
}

.block-footer{
    color: $primary;
    padding: 40px 0;
    text-align: center;
}

.block-footer__heading{
    font-size: 14px;
    font-weight: 800;
    line-height: 1.4;
    letter-spacing: .15em;
    margin: 0 0 15px 0;
    text-transform: uppercase;

    a{
        border-bottom: 1px solid transparent;
        color: $green;
        transition: all .4s;

        &:hover{
            border-color: $green;
        }

    }

}

.block-footer__copy{
    font-size: 14px;
}

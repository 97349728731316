a{
    color: inherit;
    text-decoration: none;
}

body{
    color: $black;
    font-family: $sans;
}

h1,h2,h3,h4,h5,figure{margin: 0;}

.container{
    margin: 0 auto;
    max-width: 1400px;
    width: 90%;
}

.the-content{display: none}

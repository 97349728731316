.block-navigation{
    color: $white;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: .2em;
    left: 0;
    padding: 3% 0;
    position: absolute;
    top: 0;
    text-transform: uppercase;
    width: 100%;

    @include breakpoint(to tablet-medium){
        display: none;
    }

    .boxes{
        @include layout-flex($justify: space-around);
    }

    a{
        transition: all .4s;

        &:hover{
            color: $secondary;
        }

    }

}

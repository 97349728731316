.block-intro-1{
    @include layout-flex($justify: center);
    background-size: cover;
    height: 100vh;
    text-align: center;
}

$shadow: 0 0 5px rgba($black,.75);

.block-intro-1__logo{
    margin: 0 0 25px 0;

    svg{
        $d: 135px;
        fill: $white;
        filter: drop-shadow($shadow);
        height: $d;
        width: $d;
    }
}

.block-intro-1__title{
    color: $white;
    font-size: 30px;
    font-weight: 600;
    letter-spacing: .14em;
    text-shadow: $shadow;
    text-transform: uppercase;
}


.block-intro-2{
    background-size: cover;
    height: 50vh;
}

@mixin breakpoint($string) {
  $string: _breakpoints-parse($string);
  $min-breakpoint: _breakpoints-values(nth($string, 1));
  $max-breakpoint: _breakpoints-values(nth($string, 2));

  @if ($min-breakpoint == false and $max-breakpoint == false) or ($max-breakpoint == false and $breakpoints-ie8) {
    @content;
  } @else if $min-breakpoint == false {
    @media only screen and (max-width: $max-breakpoint - 1) {
      @content;
    }
  } @else if $max-breakpoint == false {
    @media only screen and (min-width: $min-breakpoint) {
      @content;
    }
  } @else {
    @media only screen and (min-width: $min-breakpoint) and (max-width: $max-breakpoint - 1) {
      @content;
    }
  }
}

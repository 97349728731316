.block-join{
    background: $primary;
    color: $white;
    padding: 90px 0;
    text-align: center;

    .container{max-width: 770px}

}

.block-join__headline{
    font-size: 24px;
    letter-spacing: .2em;
    line-height: 1.5;
    margin: 0 auto 45px auto;
    text-transform: uppercase;
}

.block-join__action{
    font-size: 22px;

    a{
        border-bottom: 1px solid transparent;
        color: $secondary;
        transition: all .4s;

        &:hover{
            border-color: $secondary;
        }

    }

}

.block-join__announcement{
    font-size: 20px;
    font-style: italic;
    letter-spacing: .2em;
    line-height: 1.5;
    text-transform: uppercase;
    margin: 45px 0 0 0;
}

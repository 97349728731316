.block-modal{
    @include levels(4);
    @include layout-flex($justify: center);
    background: rgba($purple,.9);
    height: 100%;
    left: 0;
    opacity: 0;
    position: fixed;
    top: 0;
    transition: all .4s;
    visibility: hidden;
    width: 100%;
}

.block-modal__box{
    background: $white;
    max-width: 90%;
    padding: 30px;
    width: 560px;

    @include breakpoint(to tablet-medium){
        max-width: 100%;
    }

    h1{
        font-size: 24px;
        letter-spacing: .2em;
        margin: 0 0 25px 0;
        text-transform: uppercase;
    }

    h2{
        font-size: 22px;
        letter-spacing: .2em;
        margin: 0 0 20px 0;
        text-transform: uppercase;
    }

    h3{
        font-size: 20px;
        letter-spacing: .2em;
        margin: 0 0 15px 0;
        text-transform: uppercase;
    }

    p,ul{
        font-size: 18px;
        line-height: 1.4;
        margin: 0 0 20px 0;
    }

    ul{margin-left: 1.4em}

    article{
        max-height: 90vh;
        overflow-y: auto;
    }


}


.block-modal__close{
    color: $secondary;
    font-size: 18px;
    letter-spacing: .1em;
    text-align: right;
    text-transform: uppercase;
}


.page-id-2 .block-modal__box {
    width: 800px;
}

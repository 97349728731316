.block-header{
    @include levels(4);
    left: 0;
    padding: 7px 0;
    position: fixed;
    top: 0;
    transition: all .5s;
    width: 100%;

    @include breakpoint(from tablet-medium){
        background: $purple;
        transform: translateY(-100%);
    }

    .boxes{
        @include layout-flex;
    }

    .box-2{
        @include breakpoint(from tablet-medium){
            flex: 1;
        }
    }

}

.block-header__logo{

    svg{
        $d: 65px;
        display: block;
        fill: $white;
        height: $d;
        width: $d;
    }

}

.block-header__nav{
    @include layout-flex;
    @include levels(0);
    color: $white;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: .2em;
    padding: 0 10%;
    text-transform: uppercase;

    @include breakpoint(to tablet-medium){
        flex-direction: column;
        justify-content: center;
        background: $purple;
        height: 100vh;
        opacity: 0;
        left: 0;
        top: 0;
        visibility: hidden;
        position: fixed;
        width: 100%;
    }


    a{
        transition: all .4s;

        @include breakpoint(to tablet-medium){
            margin: 25px 0;
        }

        &:hover,&.is-active{
            color: $secondary;
        }

    }

}

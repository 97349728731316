.block-content{
    background: $white;
    padding: 100px 0;

    .container{
        max-width: 940px;
    }

}

.block-content__headline{
    font-size: 24px;
    letter-spacing: .2em;
    line-height: 1.5;
    max-width: 520px;
    margin: 0 auto 65px auto;
    text-align: center;
    text-transform: uppercase;
}

.block-content__copy{

    @include breakpoint(from tablet-medium){
        columns: 2;
        column-gap: 75px;
    }

    p{
        font-size: 18px;
        line-height: 1.5;

        &:not(:last-child){
            margin: 0 0 20px 0;
        }


    }

}

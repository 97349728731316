.state-menu-open{

    .block-header__nav{
        opacity: 1;
        visibility: visible;
    }


    .component-button-menu{

        $offset: 8px;


        .line-1{
            top: $offset;
            transform: rotate(45deg);
        }

        .line-2{
            opacity: 0;
        }

        .line-3{
            top: -$offset;
            transform: rotate(-45deg);
        }

    }

}
